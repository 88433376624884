<template>
	<div>
	<ContentHeader title="Rekam Medis" url="/rekam-medis" subTitle="Rekam Medis Detail" />
  <LoadingScreen v-if="loading" />
    <div class="content-header">
		<div class="container-fluid">
			<div class="card">
              <div class="card-header">
                <!-- <h3 class="card-title">{{ pasien.nama }} - {{ pasien.nomor_rekam_medis }}</h3> -->
              </div>
              <div class="card-body pl-4" style="display: block;">
                  <div class="row border-bottom border-dark pl-4">
                    <div class="col-7 border-right border-dark">
                      <h3>KLINIK RATNASARI SEHAT</h3>
                      <h6>Jl. Ds Ciluncat RT 02/RW 03 Kec. Cangkuang</h6>
                      <h6>Kab. Bandung Tlp. (022) 579881</h6>
                    </div>
                    <div class="col-5 pl-2">
                      <h4>No. Rekam Medis: {{ pasien.nomor_rekam_medis }}</h4>
                      <h6>Alergi Obat    :</h6>
                    </div>
                  </div>
                  <p class="text-center ml-n5">IDENTITAS PASIEN</p>
                  <div class="row pl-4">
                    <div class="col pl-4">
                      <div class="row d-flex justify-content-start">
                        <div style="width: 120px;">Nama</div>
                        <div class="mx-2">:</div>
                        <div>{{ pasien.nama }}</div>
                      </div>
                      <div class="row d-flex justify-content-start">
                        <div style="width: 120px;">Jenis Kelamin</div>
                        <div class="mx-2">:</div>
                        <div>{{ pasien.jenis_kelamin }}</div>
                      </div>
                      <div class="row d-flex justify-content-start">
                        <div style="width: 120px;">Pekerjaan</div>
                        <div class="mx-2">:</div>
                        <div>{{ pasien.pekerjaan }}</div>
                      </div>
                      <div class="row d-flex justify-content-start">
                        <div style="width: 120px;">Alamat</div>
                        <div class="mx-2">:</div>
                        <div>{{ pasien.alamat_lengkap }}</div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row d-flex justify-content-start">
                        <div style="width: 150px;">Tanggal Lahir/umur</div>
                        <div class="mx-2">:</div>
                        <div>{{ dayjs(pasien.tanggal_lahir).format('DD-MM-YYYY')  }}</div>
                      </div>
                      <div class="row d-flex justify-content-start">
                        <div style="width: 150px;">No. Telp/WA</div>
                        <div class="mx-2">:</div>
                        <div>{{ pasien.nomor_telepon ? pasien.nomor_telepon : pasien.nomor_whatsapp  }}</div>
                      </div>
                      <div class="row d-flex justify-content-start">
                        <div style="width: 150px;">Kepala Keluarga</div>
                        <div class="mx-2">:</div>
                        <div>{{ pasien.nama }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-bordered mt-4">
                      <thead>
                        <tr>
                          <th scope="col" style="min-width: 110px;" class="text-center">Tanggal</th>
                          <th scope="col" style="min-width: 200px;">Anamnesa</th>
                          <th scope="col" style="min-width: 200px;">Pemeriksaan Fisik &amp; Penunjang</th>
                          <th scope="col" style="min-width: 300px;">Diagnosa</th>
                          <th scope="col" style="min-width: 200px;">Rencana Pelaksanaan Pengobatan</th>
                          <th scope="col" style="min-width: 80px;">Paraf</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in rekam_medis_detail" :key="index">
                          <th style="min-width: 110px;" class="text-center" scope="row">{{ dayjs(row.created_at).format('DD-MM-YYYY') }}</th>
                          <td style="min-width: 200px;">{{ row.anamnesa }}</td>
                          <td class="text-sm" style="min-width: 200px;">
                            <span class="mb-2">Berat Badan  : {{ row.berat_badan }}</span> <br>
                            <span class="mb-2">Tinggi Badan  : {{ row.tinggi_badan }}</span> <br>
                            <span class="mb-2">Suhu Tubuh  : {{ row.suhu_tubuh }}</span> <br>
                            <span class="mb-2">Frekuensi Denyut Jantung  : {{ row.frekuensi_denyut_jantung }}</span> <br>
                            <span class="mb-2">Frekuensi Pernapasan  : {{ row.frekuensi_pernapasan }}</span>
                          </td>
                          <td style="min-width: 300px;">
                            <div v-if="row.nama_icdx">
                              <span>ICDX:</span>
                              <span class="ml-4 d-block" v-for="(icdx, i) in row.nama_icdx" :key="i">- {{ icdx }}</span><br>
                            </div>
                            <div v-if="row.diagnosa">
                              <span>Diagnosa:</span>
                              <span class="ml-4 d-block">- {{ row.diagnosa }}</span>
                            </div>
                          </td>
                          <td style="min-width: 200px;">
                            <div v-if="row.resep.length > 0">
                              <span>Resep dan Obat:</span><br>
                              <span class="ml-4 d-block" v-for="(resep, i) in row.resep" :key="i">- {{ resep.nama }}/Aturan Pakai : {{ resep.signa }}</span><br>
                            </div>
                            <div v-if="row.tindakan.length > 0">
                              <span>Tindakan:</span><br>
                              <span class="ml-4 d-block" v-for="(tindakan, i) in row.tindakan" :key="i">- {{ tindakan.nama }}</span><br>
                            </div>
                          </td>
                          <td style="min-width: 80px;">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import LoadingScreen from '@/components/LoadingScreen'

import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import dayjs from 'dayjs'
import store from '@/store'

const cabang = computed(() => store.getters['auth/cabang'])
const route = useRoute()
const pasien_id = route.params.id
const rekam_medis_detail = ref([])
const pasien = ref([])
const loading = ref(false)

const getPasien = async () => {
  let { data } = await axios.get(`api/pasien/getWhereId/${pasien_id}/${cabang.value.tipe}`)

  pasien.value = data
  if (pasien.value.jenis_kelamin == 'P') {
    pasien.value.jenis_kelamin = 'Perempuan'
  } else if(pasien.value.jenis_kelamin == 'L') {
    pasien.value.jenis_kelamin = 'Laki-laki'
  }
}

const getRekamMedis = async () => {
  loading.value = true
  await axios.get(`api/rekam_medis/getDetailRekamMedis/${pasien_id}`)
  .then((res) => {
    console.log(res)
    if(res.data == 'kosong') {
      rekam_medis_detail.value = []
    } else {
      rekam_medis_detail.value = res.data
    }
  })  
  .finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  getPasien()
  getRekamMedis()
})
</script>